import LocalStorageUtils from './localStorage';

const getFlagValue = (flag: string): boolean => {
  const flags = LocalStorageUtils.getFeatureFlags();
  if (flag && flags && flags[flag]) {
    const jsonFlag = flags[flag];
    return jsonFlag['key'] === 'on';
  } else {
    return false;
  }
};
export default getFlagValue;
