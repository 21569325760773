import React, { Component } from 'react';
import { Route, BrowserRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { NavigationContext } from '@common/contexts/navigationContext';
import {
  NavigationContextInterface,
  SplunkCompleteLogger,
  SplunkErrorLogger,
  SplunkInfoLogger,
  StationLocationObject,
  ActivatedObjData,
  GeoLocationCoords,
  LatitudeLongitude,
  configData,
} from '@common/interfaces';
import {
  isMin80Percent,
  toBoolean,
  getVehicleChargingTime,
  LocalStorageUtils,
} from '@common/utilities';
import { v4 as uuidv4 } from 'uuid';
import {
  getFastChargingStatus,
  getSyncChargingStatus,
  getChargeTargetReachedStatus,
} from '@common/stores/syncChargingStatus.store';
import { ChargingLocations } from './components/chargingLocations/chargingLocations';
import { OfflineModal } from './components/modals/connectionOfflineModal/offlineModal';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import versionData from './versionData.json';
import hmiMqttService from '@common/services/hmiMqttService/hmiMqtt.service';
import {
  EV_CHARGE_PROGRAMMING_VALUE_VEHICLE_ELECTRIC_RANGE_LENGTH,
  EV_CHARGE_PROGRAMMING_VALUE_ACTIVE_PLUGGED_IN,
  EV_CHARGE_PROGRAMMING_VALUE_FAST_CHARGE_COMPLETION_ESTIMATE,
  EV_CHARGE_PROGRAMMING_VALUE_FAST_CHARGE_BULK_ESTIMATE,
} from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_ev_charge-programming';
import {
  EV_CHARGE_STATUS_VALUE_STATE_OF_CHARGE,
  EV_CHARGE_STATUS_VALUE_STATUS_D2,
  EV_CHARGE_STATUS_VALUE_CHARGE_NOW_DURATION,
} from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_ev_charge-status';
import { VEHICLE_VALUE_VEHICLE_PROGRAM } from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_vehicle';
import { TRIPS_IOD_VALUE_DISTANCE_UNIT } from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_trips-iod';
import { CCS_VALUE_VEHICLE_CONNECTIVITY_ALLOWED } from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_ccs';
import { TIME_VALUE_TIME_FORMAT } from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_time';
import SplunkLogger from '@common/services/splunk.service';
import { DIAGNOSTICS_VALUE_APIM_VIN } from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_diagnostics';
import { DRIVER_RESTRICTIONS_VALUE_GENERAL_APPS_ACTIVE } from '../node_modules/hmi-mqtt/dist/topic-flow/hmi_driver-restrictions';
import i18n from '@common/config/i18n';
import { vehicleType } from '@common/utilities/vehicleType';
import { VEHICLE_TYPE } from '@common/enums/vehicleType.enum';
import { RetailHeader } from './components/retailHeader/retailHeader';
import GeoLocationService from '@common/services/geoLocation.service';
import WebSocketService from '@common/services/webSocketService/webSocket.service';
import ConfigDataService from '@common/services/getConfigData.service';
import { renderSpinner } from '@common/utilities/determineSpinner';
import { getAppLanguage } from '@common/utilities/getAppLanguage';

const ViewNearbyChargers = React.lazy(
  () => import('./components/viewNearbyChargers/viewNearbyChargers')
);
import FlagService from '@common/services/flag.service';
import haversine from 'haversine-distance';
import NavigationService from '@common/services/navigation.service';
import DriversDistractionBanner from './components/driversDistractionBanner/DriversDistractionBanner';
import { H } from '@common/HereMap/index';
import {
  ic_go_charging,
  ic_map_station_not_compatible,
  ic_vehicle_location,
} from '@common/assets/images';
import CvfeService from '@common/services/cvfe.service';

export interface AppProps extends RouteComponentProps {
  t: (arg0: string) => string;
}

export interface AppState {
  batteryChargedPercent: number;
  battery80PercentCharged: boolean;
  currentBatteryRange: string;
  vehicleIsCharging: boolean | null;
  vehicleIsFastCharging: boolean | null;
  vehiclePluggedIn: boolean;
  isChargeTargetReached: boolean;
  timeToCharge80PercentComplete: string | null;
  timeToCharge100PercentComplete: string | null;
  timeToCharge100PercentForAc: any;
  isDriverRestrictionsActive: boolean;
  preferredDistanceUnit: string;
  navContextState: NavigationContextInterface;
  isOfflineModalOpen: boolean | null;
  isVehicleConnectivityAllowed: boolean;
  totalChargingTime: number | string;
  startChargingTime: number;
  vehicleGeoLocation: any | null;
  vehicleType: VEHICLE_TYPE;
  toggleViewNearByChargers: boolean;
  timeFormatUnit: string;
  vehicleCharged: boolean;
  historyObj: any;
  navigationAvailable: boolean | null;
  selectedConnectorInfoObj: ActivatedObjData | null;
  selectedChargerInfoObjData: StationLocationObject | null;
  currentLocation: GeoLocationCoords | null;
  lastSortedLocation: GeoLocationCoords | null;
  lessThan50: boolean;
  plugInTimeOut: boolean;
  configData: configData;
  isShowDriverDistractionBanner: boolean;
}

declare global {
  interface Window {
    fordSync: any;
    caches: any;
    mapFunction: any;
    navigateToLocation: any;
  }
}

class App extends Component<AppProps, AppState> {
  readonly socAndDTEdataService: hmiMqttService;
  readonly geoLocationService: GeoLocationService;
  readonly webSocketService: WebSocketService;
  readonly flagService: FlagService;
  readonly navigationService: NavigationService;
  readonly configDataService: ConfigDataService;
  readonly cvfeService: CvfeService;
  mapTypes: any = null;
  hereMapPlatform: any = null;
  rasterTileLayer: any = null;
  notCompatibleIcon: any = null;
  chargerIcon: any = null;
  vehiceLocationIcon: any = null;

  constructor(props: AppProps) {
    super(props);
    const unit = LocalStorageUtils.getPreferredDistanceUnit();
    this.state = {
      batteryChargedPercent: 0,
      battery80PercentCharged: false,
      currentBatteryRange: '---',
      vehicleIsCharging: null,
      vehicleIsFastCharging: null,
      vehiclePluggedIn: false,
      isChargeTargetReached: false,
      timeToCharge80PercentComplete: null,
      timeToCharge100PercentComplete: null,
      timeToCharge100PercentForAc: null,
      isDriverRestrictionsActive: false,
      preferredDistanceUnit: unit === null ? 'mi' : unit,
      navContextState: this.generateContext(),
      isOfflineModalOpen: false,
      isVehicleConnectivityAllowed: true,
      totalChargingTime: 0,
      startChargingTime: LocalStorageUtils.getChargeStartTime(),
      vehicleGeoLocation: null,
      vehicleType: VEHICLE_TYPE.HMI,
      toggleViewNearByChargers: false,
      timeFormatUnit: '24',
      vehicleCharged: false,
      historyObj: null,
      navigationAvailable: false,
      selectedConnectorInfoObj: null,
      selectedChargerInfoObjData: null,
      currentLocation: null,
      lastSortedLocation: null,
      lessThan50: false,
      plugInTimeOut: false,
      configData: {},
      isShowDriverDistractionBanner: false,
    };

    this.socAndDTEdataService = new hmiMqttService();
    this.geoLocationService = new GeoLocationService();
    this.webSocketService = new WebSocketService();
    this.flagService = new FlagService();
    this.navigationService = new NavigationService();
    this.configDataService = new ConfigDataService();
    this.onVehicleClientMessageArrived = this.onVehicleClientMessageArrived.bind(this);
    this.cvfeService = new CvfeService();
  }

  private readonly onVehicleMsgComplete = (
    functionName: string,
    statusDesc: string,
    traceID: string
  ) => {
    const completeObj: SplunkCompleteLogger = {
      service: 'onvehicleclientmessagearrived',
      statusCode: '',
      traceID,
      completeTime: new Date().getTime(),
      statusDesc,
      functionName,
      correlationID: traceID,
    };
    SplunkLogger.complete(completeObj);
  };

  private readonly onVehicleMsgError = (functionName: string, error: string, traceID: string) => {
    const errorObj: SplunkErrorLogger = {
      service: 'onvehicleclientmessagearrived',
      statusCode: '',
      traceID,
      completeTime: new Date().getTime(),
      error,
      functionName,
      correlationID: traceID,
    };
    SplunkLogger.error(errorObj);
  };

  getVehicleSignal(message: any, traceID: string): any {
    let vehicleSignal;
    try {
      vehicleSignal = this.socAndDTEdataService.getTopicValue(message);
      this.onVehicleMsgComplete(message.destinationName, `${vehicleSignal}`, traceID);
    } catch (error) {
      const typedError = error as Error;
      this.onVehicleMsgError(message.destinationName, typedError?.message, traceID);
    }
    return vehicleSignal;
  }

  setVehicleChargingTime(vehicleIsChargingSignal: string): void {
    const vehicleIsChargingStatus = getSyncChargingStatus(vehicleIsChargingSignal);
    if (vehicleIsChargingStatus) {
      if (!LocalStorageUtils.getChargeStartTime()) {
        LocalStorageUtils.setChargeStartTime(new Date().getTime());
      }
      this.setState({ startChargingTime: LocalStorageUtils.getChargeStartTime() });
    } else {
      this.getChargingTime(new Date().getTime());
    }
  }

  handleConnectionChange = () => {
    this.setState({ isOfflineModalOpen: true });
  };

  closeOfflineModal = (): void => {
    this.setState({ isOfflineModalOpen: false });
  };

  setVehicleCharged = (): void => {
    this.setState({ vehicleCharged: false });
    LocalStorageUtils.clearConnectorIdentifierConfigs();
    LocalStorageUtils.clearActivatingStatusConfigs();
    LocalStorageUtils.clearCurrentlyChargingLocationID();
    LocalStorageUtils.clearCallbackId();
  };

  getChargingTime = (endTime: number): void => {
    if (this.state.startChargingTime !== 0) {
      const time = getVehicleChargingTime(this.state.startChargingTime, endTime);
      this.setState({ totalChargingTime: time });
    }
  };

  setNavigationAvailable(): void {
    const trace_ID = uuidv4();

    try {
      const fordSync = window.fordSync;

      const navigationAvailableValue = fordSync.isNavAvailable();
      this.setState({ navigationAvailable: navigationAvailableValue });

      const statusDesc = `isNavigationAvailable: , ${navigationAvailableValue}`;

      this.onVehicleMsgComplete('fordSync.isNavAvailable()', statusDesc, trace_ID);
    } catch (error) {
      const typedError = error as Error;
      this.onVehicleMsgError('fordSync.isNavAvailable()', typedError?.message, trace_ID);
    }
  }

  deactivateAndNavigateToSummary(): void {
    if (!this.state.vehicleIsCharging && this.state.vehicleCharged) {
      const callBackID = LocalStorageUtils.getCallbackId();
      if (callBackID) {
        this.webSocketService.deactivate().catch((error) => {
          const traceID = uuidv4();
          const errorObj: SplunkErrorLogger = {
            service: 'App component',
            functionName: 'deactivate',
            traceID,
            completeTime: new Date().getTime(),
            error: error.message,
            statusCode: '',
            correlationID: traceID,
          };
          SplunkLogger.error(errorObj);
        });
      }

      if (
        this.state.historyObj &&
        LocalStorageUtils.getActivatedConnectorInfo() &&
        LocalStorageUtils.getActiveChargerInfo()
      ) {
        const selectedChargerInfoObjData = JSON.parse(LocalStorageUtils.getActiveChargerInfo());
        selectedChargerInfoObjData.lessThan50 = true;
        this.setChargerInfo(selectedChargerInfoObjData);
        this.setConnectorInfo(JSON.parse(LocalStorageUtils.getActivatedConnectorInfo()));
        this.state.historyObj.push('/hmpcChargingLocations');
      }
    }
  }

  getEnrollmentType(): any {
    const enrollmentType = LocalStorageUtils.getEnrollmentType();
    if (enrollmentType != null) return enrollmentType;

    return this.cvfeService
      .enrollmentType()
      .then((response) => {
        const featureCode = response.data.results.featureCode;
        const enrollmentTypeFromApi = featureCode ? JSON.stringify(featureCode) : 'Unknown';
        LocalStorageUtils.setEnrollmentType(enrollmentTypeFromApi);
        return enrollmentTypeFromApi;
      })
      .catch((error) => {
        const trace_ID = uuidv4();
        SplunkLogger.error({
          service: 'App component',
          functionName: 'getEnrollmentType',
          traceID: trace_ID,
          completeTime: new Date().getTime(),
          error: error.message,
          statusCode: '',
          correlationID: trace_ID,
        });
        return 'Unknown';
      });
  }

  async getConfigDataService(trace_ID: string): Promise<any> {
    return await this.configDataService.getConfigData(trace_ID);
  }

  themeChangeCallback = (event) => {
    const trace_ID = uuidv4();
    const theme_info: SplunkInfoLogger = {
      service: 'ford sync theme',
      functionName: 'window.fordSync.getTheme()',
      statusDesc: `Sync theme --, event.period -- ${event.period}`,
      logTime: new Date().getTime(),
      traceID: trace_ID,
      correlationID: trace_ID,
    };
    SplunkLogger.log(theme_info);
    this.applyAppTheme(event.period);
  };

  applyAppTheme(theme: string): void {
    if (theme == 'day') {
      window.document.documentElement.setAttribute('data-theme', 'light-retail');
    } else {
      window.document.documentElement.setAttribute('data-theme', 'dark-retail');
    }
  }

  clearLocalStorageValues(): void {
    if (this.state.isDriverRestrictionsActive) {
      LocalStorageUtils.clearConnectorIdentifierConfigs();
      LocalStorageUtils.clearActivatingStatusConfigs();
      LocalStorageUtils.clearCurrentlyChargingLocationID();
      LocalStorageUtils.clearCallbackId();
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('offline', this.handleConnectionChange);
    const clearTimeOutPlugin = LocalStorageUtils.getTimeOutPlugIn() as any;
    clearTimeout(clearTimeOutPlugin);
    LocalStorageUtils.clearTimeOutPlugIn();
    document.removeEventListener('navigateToChargingLocation', this.onMapFunction, false);
    document.removeEventListener('navigateToGEOLocation', this.onNavigateToLocation, false);
  }

  createIcon = (svgContent, options) => new H.map.Icon(svgContent, options);

  handleHereMaps = () => {
    if (!this.hereMapPlatform) {
      this.hereMapPlatform = new H.service.Platform({
        apikey: this.state.configData.hereMap,
      });
      const rasterTileService = this.hereMapPlatform.getRasterTileService({
        queryParams: {
          style: 'lite.day',
          size: 512,
        },
      });
      const rasterTileProvider = new H.service.rasterTile.Provider(rasterTileService);
      this.rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
      this.mapTypes = this.hereMapPlatform.createDefaultLayers();
      this.notCompatibleIcon = this.createIcon(ic_map_station_not_compatible, {});
      this.chargerIcon = this.createIcon(ic_go_charging, {});
      this.vehiceLocationIcon = this.createIcon(ic_vehicle_location, {});
    }
    return {
      hereMapPlatform: this.hereMapPlatform,
      rasterTileLayer: this.rasterTileLayer,
      mapTypes: this.mapTypes,
      notCompatibleIcon: this.notCompatibleIcon,
      chargerIcon: this.chargerIcon,
      vehiceLocationIcon: this.vehiceLocationIcon,
    };
  };

  languageChangeCallback = (event: any) => {
    const trace_ID = uuidv4();
    try {
      const newLanguage = getAppLanguage();
      i18n.changeLanguage(newLanguage);
      LocalStorageUtils.setLanguageCode(newLanguage);
      const language_event_listener_try: SplunkInfoLogger = {
        service: 'ford sync language event listener set language',
        functionName: 'window.fordSync. event listener try',
        statusDesc: `event language ${event.language}`,
        logTime: new Date().getTime(),
        traceID: trace_ID,
        correlationID: trace_ID,
      };
      SplunkLogger.log(language_event_listener_try);
    } catch (error) {
      const trace_ID = uuidv4();
      const typedError = error as Error;
      const language_event_listener_error: SplunkErrorLogger = {
        service: 'ford sync language event listener set language error',
        functionName: 'window.fordSync.getLanguage()',
        traceID: trace_ID,
        completeTime: new Date().getTime(),
        error: typedError.message,
        statusCode: '',
        correlationID: trace_ID,
      };
      SplunkLogger.error(language_event_listener_error);
    }
  };

  onVehicleClientMessageArrived(message: any, traceID: any): void {
    const signal = this.getVehicleSignal(message, traceID);
    switch (message.destinationName) {
      case EV_CHARGE_STATUS_VALUE_STATE_OF_CHARGE:
        this.setState({
          batteryChargedPercent: parseInt(signal),
          battery80PercentCharged: isMin80Percent(signal),
        });
        break;
      case EV_CHARGE_PROGRAMMING_VALUE_VEHICLE_ELECTRIC_RANGE_LENGTH:
        this.setState({
          currentBatteryRange: signal,
        });
        break;
      case EV_CHARGE_STATUS_VALUE_STATUS_D2:
        this.handleD2Signal(signal);
        break;
      case EV_CHARGE_PROGRAMMING_VALUE_ACTIVE_PLUGGED_IN:
        this.setState({
          vehiclePluggedIn: toBoolean(signal),
        });
        break;
      case EV_CHARGE_PROGRAMMING_VALUE_FAST_CHARGE_BULK_ESTIMATE:
        this.setState({
          timeToCharge80PercentComplete: signal,
        });
        break;
      case EV_CHARGE_PROGRAMMING_VALUE_FAST_CHARGE_COMPLETION_ESTIMATE:
        this.setState({
          timeToCharge100PercentComplete: signal,
        });
        break;
      case EV_CHARGE_STATUS_VALUE_CHARGE_NOW_DURATION:
        this.setState({
          timeToCharge100PercentForAc: signal,
        });
        break;
      case DRIVER_RESTRICTIONS_VALUE_GENERAL_APPS_ACTIVE:
        this.setState({ isDriverRestrictionsActive: toBoolean(signal) }, () => {
          this.clearLocalStorageValues();
        });
        break;
      case CCS_VALUE_VEHICLE_CONNECTIVITY_ALLOWED:
        this.setState({
          isVehicleConnectivityAllowed: toBoolean(signal),
        });
        break;
      case TRIPS_IOD_VALUE_DISTANCE_UNIT:
        this.handleDistanceUnitSignal(signal);
        break;
      case DIAGNOSTICS_VALUE_APIM_VIN:
        {
          LocalStorageUtils.setVinNumber(signal);
          this.flagService.postIsFlagEnabled().then((flagResult: any) => {
            LocalStorageUtils.setFeatureFlags(JSON.stringify(flagResult.data.flags));
          });
        }
        break;
      case TIME_VALUE_TIME_FORMAT:
        this.setState({
          timeFormatUnit: signal === '1' ? '12' : '24',
        });
        break;
      case VEHICLE_VALUE_VEHICLE_PROGRAM:
        LocalStorageUtils.setDeviceType(signal);
        break;
    }
  }

  async componentDidMount(): Promise<void> {
    this.getEnrollmentType();
    const trace_ID = uuidv4();
    const version_info: SplunkInfoLogger = {
      service: 'hmpc app version',
      functionName: 'componentDidMount',
      statusDesc: `version: ${versionData.buildRevision} && ${versionData.tagNumber} `,
      logTime: new Date().getTime(),
      traceID: trace_ID,
      correlationID: trace_ID,
    };
    SplunkLogger.log(version_info);

    document.addEventListener('navigateToChargingLocation', this.onMapFunction, false);
    document.addEventListener('navigateToGEOLocation', this.onNavigateToLocation, false);
    window.addEventListener('offline', this.handleConnectionChange);
    window.addEventListener('systemthemeperiodchange', this.themeChangeCallback);

    LocalStorageUtils.clearSearchedValue();
    LocalStorageUtils.clearAutosuggestLatAndLong();

    this.updateLocationAndChargerDistances();

    const fordSync = window.fordSync;
    const currentLanguage = getAppLanguage();

    this.setLanguageInLocalStorage(currentLanguage);

    const language_info: SplunkInfoLogger = {
      service: 'ford sync language',
      functionName: 'window.fordSync.getLanguage()',
      statusDesc: currentLanguage,
      logTime: new Date().getTime(),
      traceID: trace_ID,
      correlationID: trace_ID,
    };

    SplunkLogger.log(language_info);

    this.onVehicleMsgComplete('ford sync current language', currentLanguage, trace_ID);

    i18n.changeLanguage(currentLanguage);

    window.addEventListener('systemLanguageChange', this.languageChangeCallback);

    // set the app theme while not running in the FordSync environment
    if (window && !window.fordSync) {
      window.document.documentElement.setAttribute('data-theme', 'light-retail');
    }

    if (window && window.fordSync) {
      const viewport = window.fordSync.getViewport();
      const theme = window.fordSync.getTheme();
      this.applyAppTheme(theme);

      const data = await this.getConfigDataService(trace_ID);

      this.setState({ configData: data, vehicleType: vehicleType(viewport) });

      this.setNavigationAvailable();

      fordSync.onvehicleclientmessagearrived = this.onVehicleClientMessageArrived;

      window.fordSync.vehicleClientSubscribe(EV_CHARGE_STATUS_VALUE_STATE_OF_CHARGE);
      window.fordSync.vehicleClientSubscribe(
        EV_CHARGE_PROGRAMMING_VALUE_VEHICLE_ELECTRIC_RANGE_LENGTH
      );
      window.fordSync.vehicleClientSubscribe(EV_CHARGE_STATUS_VALUE_STATUS_D2);
      window.fordSync.vehicleClientSubscribe(DIAGNOSTICS_VALUE_APIM_VIN);
      window.fordSync.vehicleClientSubscribe(TRIPS_IOD_VALUE_DISTANCE_UNIT);
      window.fordSync.vehicleClientSubscribe(DRIVER_RESTRICTIONS_VALUE_GENERAL_APPS_ACTIVE);
      window.fordSync.vehicleClientSubscribe(EV_CHARGE_PROGRAMMING_VALUE_ACTIVE_PLUGGED_IN);
      window.fordSync.vehicleClientSubscribe(EV_CHARGE_PROGRAMMING_VALUE_FAST_CHARGE_BULK_ESTIMATE);
      window.fordSync.vehicleClientSubscribe(EV_CHARGE_STATUS_VALUE_CHARGE_NOW_DURATION);
      window.fordSync.vehicleClientSubscribe(
        EV_CHARGE_PROGRAMMING_VALUE_FAST_CHARGE_COMPLETION_ESTIMATE
      );
      window.fordSync.vehicleClientSubscribe(TIME_VALUE_TIME_FORMAT);
      window.fordSync.vehicleClientSubscribe(VEHICLE_VALUE_VEHICLE_PROGRAM);
      window.fordSync.vehicleClientSubscribe(CCS_VALUE_VEHICLE_CONNECTIVITY_ALLOWED);
    }
  }

  onRouteChange = (isViewNearByChargersRoute) => {
    this.setState({
      toggleViewNearByChargers: isViewNearByChargersRoute,
    });
  };

  setHistoryObj = (historyVal) => {
    this.setState({
      historyObj: historyVal,
    });
  };

  setConnectorInfo = (selectedConnectorInfoObj) => {
    this.setState({ selectedConnectorInfoObj: selectedConnectorInfoObj });
  };

  setChargerInfo = (selectedChargerInfoObjData) => {
    this.setState({
      selectedChargerInfoObjData: selectedChargerInfoObjData,
      lessThan50: selectedChargerInfoObjData?.lessThan50 ?? false,
    });
  };

  setStartChargingTime = () => {
    this.setState({ totalChargingTime: 0, startChargingTime: 0 });
  };

  setPlugInTimeOut = (isPlugInTimeout) => {
    let clearTimeOutPlugin;
    const plugInTimeOutValue = JSON.parse(
      process.env.REACT_APP_HMPC_PLUG_IN_TIMEOUT_SETTING || '60000'
    );
    if (isPlugInTimeout) {
      clearTimeOutPlugin = setTimeout(() => {
        this.setState({
          plugInTimeOut: true,
        });
      }, plugInTimeOutValue);
      LocalStorageUtils.setTimeOutPlugIn(clearTimeOutPlugin);
    } else {
      this.setState({
        plugInTimeOut: false,
      });
    }
  };

  updateLocationAndChargerDistances = (): void => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position: GeoLocationCoords) => {
        if (this.state.currentLocation === null) {
          this.setState({ currentLocation: position, lastSortedLocation: position });
          this.geoLocationService.saveLocationObj(position);
        } else {
          const vehicleDistance = haversine(
            this.state.currentLocation.coords as LatitudeLongitude,
            position.coords as LatitudeLongitude
          );
          if (vehicleDistance >= 20000) {
            this.setState({ currentLocation: position, lastSortedLocation: position });
            this.geoLocationService.saveLocationObj(position);
          }
        }
      });
    }
  };

  setLanguageInLocalStorage = (currentLanguage: string): void => {
    if (currentLanguage) {
      LocalStorageUtils.setLanguageCode(currentLanguage);
    } else {
      LocalStorageUtils.setLanguageCode('en_US');
    }
  };

  handleD2Signal = (signal: any): void => {
    this.setState(
      {
        vehicleIsCharging: getSyncChargingStatus(signal),
        vehicleIsFastCharging: getFastChargingStatus(signal),
        isChargeTargetReached: getChargeTargetReachedStatus(signal),
      },
      () => {
        if (this.state.vehicleIsCharging) {
          this.setState({ vehicleCharged: true });
          const chargedPercentage = LocalStorageUtils.getStartingVehicleChargePercentage();
          if (!chargedPercentage) {
            LocalStorageUtils.setStartingVehicleChargePercentage(this.state.batteryChargedPercent);
          }
        }
        this.deactivateAndNavigateToSummary();
      }
    );
    this.setVehicleChargingTime(signal);
  };

  handleDistanceUnitSignal = (signal: any): void => {
    const unit = signal === '1' ? 'km' : 'mi';
    if (signal !== null) {
      this.setState({
        preferredDistanceUnit: unit,
      });
      LocalStorageUtils.setPreferredDistanceUnit(unit);
    }
  };

  handleDistanceUnitSignalCallback = (unitFromRgion) => {
    const unit = LocalStorageUtils.getPreferredDistanceUnit();
    if (unit === null) {
      const unitCode: string = unitFromRgion === 'NA' ? 'mi' : 'km';
      this.setState({
        preferredDistanceUnit: unitCode,
      });
    }
  };

  generateContext(): NavigationContextInterface {
    return {
      isInitialLoad: true,
      geoLocationObj: null,
      latitude: null,
      longitude: null,
      regionCode: '',
    };
  }

  updateDistanceUnit = (value: string): void => {
    this.setState({ preferredDistanceUnit: value });
  };

  updateLastSortedLocation = (location: GeoLocationCoords): void => {
    this.setState({ lastSortedLocation: location });
  };

  setLessThan50 = (val: boolean): void => {
    this.setState({ lessThan50: val });
  };

  onNavigateToLocation = (event) => {
    if (event && event['detail']) {
      this.navigationService.startNavigation(event['detail']);
    }
  };

  onMapFunction = (event) => {
    if (event && event['detail']) {
      this.setChargerInfo({ ...event['detail'], lessThan50: event['detail'].distance <= 50 });
      this.state.historyObj?.push('/hmpcChargingLocations');
    }
  };

  setDriverDistractionBannerStatus = (status: boolean): void => {
    this.setState({
      isShowDriverDistractionBanner: status,
    });
  };

  render(): React.ReactNode {
    const { t } = this.props;
    const { isOfflineModalOpen, isVehicleConnectivityAllowed } = this.state;
    return (
      <div data-testid="public-charging-app">
        <DriversDistractionBanner
          showBanner={this.state.isShowDriverDistractionBanner}
          t={t}
          closeBanner={() => this.setDriverDistractionBannerStatus(false)}
        />
        {isOfflineModalOpen || !isVehicleConnectivityAllowed ? (
          <OfflineModal
            t={t}
            isOpen={isOfflineModalOpen || !isVehicleConnectivityAllowed}
            closeModal={this.closeOfflineModal}
            data-testid="offline-modal"
            isVehicleConnectivityAllowed={isVehicleConnectivityAllowed}
            //showtext=true or false
          />
        ) : null}
        <NavigationContext.Provider value={this.state.navContextState}>
          <BrowserRouter>
            <RetailHeader
              t={t}
              vehiclePluggedIn={this.state.vehiclePluggedIn}
              vehicleIsCharging={this.state.vehicleIsCharging}
              history={this.state.historyObj}
              isDriverRestrictionsActive={this.state.isDriverRestrictionsActive}
              openDriverRestrictionsModal={() => this.setDriverDistractionBannerStatus(true)}
            />
            <ScrollToTop />
            <React.Suspense fallback={renderSpinner(this.state.vehicleType)}>
              <Route
                exact
                path="/"
                render={(props: any) => (
                  <ViewNearbyChargers
                    {...props}
                    vehicleType={this.state.vehicleType}
                    vehicleIsCharging={this.state.vehicleIsCharging}
                    vehicleGeoLocationObj={this.state.vehicleGeoLocation}
                    preferredDistanceUnit={this.state.preferredDistanceUnit}
                    navigationAvailable={this.state.navigationAvailable}
                    isDriverRestrictionsActive={this.state.isDriverRestrictionsActive}
                    onRouteChange={this.onRouteChange}
                    passHistoryObj={this.setHistoryObj}
                    setChargerInfo={this.setChargerInfo}
                    setConnectorInfo={this.setConnectorInfo}
                    setLessThan50={this.setLessThan50}
                    currentLocation={this.state.currentLocation}
                    lastSortedLocation={this.state.lastSortedLocation}
                    updateLastSortedLocation={this.updateLastSortedLocation}
                    handleDistanceUnitSignalCallback={this.handleDistanceUnitSignalCallback}
                    configData={this.state.configData}
                    openDriverRestrictionsModal={() => this.setDriverDistractionBannerStatus(true)}
                    handleHereMaps={this.handleHereMaps}
                  />
                )}
              />
            </React.Suspense>
            <Route
              path="/hmpcChargingLocations"
              render={(props) => (
                <ChargingLocations
                  {...props}
                  t={this.props.t}
                  preferredDistanceUnit={this.state.preferredDistanceUnit}
                  selectedChargerInfoObjData={this.state.selectedChargerInfoObjData}
                  vehicleIsCharging={this.state.vehicleIsCharging}
                  navigationAvailable={this.state.navigationAvailable}
                  setConnectorInfo={this.setConnectorInfo}
                  setPlugInTimeOut={this.setPlugInTimeOut}
                  passHistoryObj={this.setHistoryObj}
                  lessThan50={this.state.lessThan50}
                  setLessThan50={this.setLessThan50}
                  batteryChargedPercent={this.state.batteryChargedPercent}
                  currentBatteryRange={this.state.currentBatteryRange}
                  battery80PercentCharged={this.state.battery80PercentCharged}
                  timeToCharge80PercentComplete={this.state.timeToCharge80PercentComplete}
                  timeToCharge100PercentComplete={this.state.timeToCharge100PercentComplete}
                  timeToCharge100PercentForAc={this.state.timeToCharge100PercentForAc}
                  vehiclePluggedIn={this.state.vehiclePluggedIn}
                  isChargeTargetReached={this.state.isChargeTargetReached}
                  vehicleType={this.state.vehicleType}
                  plugInTimeOut={this.state.plugInTimeOut}
                  vehicleCharged={this.state.vehicleCharged}
                  setVehicleCharged={this.setVehicleCharged}
                  isDriverRestrictionsActive={this.state.isDriverRestrictionsActive}
                  openDriverRestrictionsModal={() => this.setDriverDistractionBannerStatus(true)}
                />
              )}
            />
          </BrowserRouter>
        </NavigationContext.Provider>
      </div>
    );
  }
}

export default withTranslation('translations')(App as any);
export { App };
