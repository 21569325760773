export const getMaxConnectorSpeed = (chargers: any): string => {
  const evsePowerLevels = ['Ultra Fast', 'Fast', 'Regular'];

  let defaultPowerLevel = 'Regular';

  if (chargers && chargers.length > 0) {
    outerLoop: for (const evsePowerLevelStr of evsePowerLevels) {
      for (const charger of chargers) {
        const filtered = charger.connectors.filter((connector) => {
          return connector.evsePowerLevel === evsePowerLevelStr;
        });

        if (filtered.length > 0) {
          defaultPowerLevel = evsePowerLevelStr;
          break outerLoop;
        }
      }
    }
  }

  return defaultPowerLevel;
};
