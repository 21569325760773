import { LocalStorageUtils } from '@common/utilities';
import { COUNTRY_CODE, COUNTRY_NUMBERS } from '@common/enums';

export const getCountryCode = (): string => {
  const languageCode: string = LocalStorageUtils.getLanguageCode();
  switch (languageCode) {
    case COUNTRY_CODE.EN_US:
      return `${COUNTRY_NUMBERS.US}, option 3`;
    case COUNTRY_CODE.ES_US:
      return `${COUNTRY_NUMBERS.US}, opción 3`;
    case COUNTRY_CODE.FR_CA:
      return `${COUNTRY_NUMBERS.CA}, option 3`;
    case COUNTRY_CODE.EN_GB:
      return COUNTRY_NUMBERS.UK;
    case COUNTRY_CODE.ES_ES:
      return COUNTRY_NUMBERS.ES;
    case COUNTRY_CODE.IT_IT:
      return COUNTRY_NUMBERS.IT;
    case COUNTRY_CODE.DE_DE:
      return COUNTRY_NUMBERS.DE;
    case COUNTRY_CODE.FR_FR:
      return COUNTRY_NUMBERS.FR;
    case COUNTRY_CODE.NL_BE:
      return COUNTRY_NUMBERS.BE;
    case COUNTRY_CODE.NL_NL:
      return COUNTRY_NUMBERS.NL;
    default:
      return `${COUNTRY_NUMBERS.US}, option 3`;
  }
};
